import { MenuMobile } from '/features/buildingBlocks/MenuMobile'
import { useTranslate } from '/machinery/I18n'
import { Icon } from '/features/buildingBlocks/Icon'

import crossIcon from '/images/icons/cross.raw.svg'

import styles from './NavigationMobile.css'

export function NavigationMobile({ items, button, layoutClassName, linksToTranslations }) {
  const [subMenuItem, setSubMenuItem] = React.useState(null)
  const [expand, setExpand] = React.useState(false)
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.inner}>
        <MenuButton value={expand} onChange={setExpand} />
      </div>
      {expand &&
      <MenuMobile
        layoutClassName={styles.menuMobile}
        onActiveItemChange={handleActiveItemChange}
        {... { items, subMenuItem, button, linksToTranslations }}
      />}
    </div>
  )

  function handleActiveItemChange(item) {
    setSubMenuItem(subMenuItem === item ? null : item)
  }
}

function MenuButton({ onChange, value }) {
  const { __ } = useTranslate()
  return (
    <div className={styles.componentMenuButton}>
      {value
        ? <button data-x='click-to-close-menu' className={styles.buttonCross} onClick={() => onChange(!value)}><Icon icon={crossIcon} layoutClassName={styles.icon} /></button>
        : <button data-x='click-to-open-menu' className={styles.buttonMenu} onClick={() => onChange(!value)} > {__`menu`}</button>
      }
    </div>
  )
}
