import { LanguageSwitch } from '/features/buildingBlocks/LanguageSwitch'
import { LinkMenuMobile } from '/features/buildingBlocks/Link'
import { extractLinkInfo } from '/machinery/extractLinkInfo'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { useElementSize } from '@kaliber/use-element-size'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'
import { Icon } from '/features/buildingBlocks/Icon'

import chevronDown from '/images/icons/chevron-menu-down.raw.svg'

import styles from './MenuMobile.css'

export function MenuMobile({ onActiveItemChange, items, subMenuItem, layoutClassName, button, linksToTranslations }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <ul className={styles.items}>
        {items.map((x, index) => (
          <li className={styles.item} key={index}>
            {['internalLink', 'externalLink'].includes(x._type)
              ? <LinkMenuMobile dataX='link-in-menu' href={extractLinkInfo(x)?.href} target={extractLinkInfo(x)?.target}>
                {extractLinkInfo(x)?.label}
              </LinkMenuMobile>
              :
              <SubMenu
                layoutClassName={styles.subMenu}
                id={x._id}
                expand={x === subMenuItem}
                onExpand={expand => onActiveItemChange(expand ? x : null)}
                items={x.items}
                label={x.label}
              />
            }
          </li>
        ))}
      </ul>
      <div className={styles.extra}>
        <ButtonPrimary
          dataX='cta-in-menu'
          href={determineDocumentPathSync({ document: button.ref, routeMap })}
        >
          {button.ref.title}
        </ButtonPrimary>

        <LanguageSwitch {...{ linksToTranslations }} layoutClassName={styles.languageLayout} />

      </div>
    </div>
  )
}

function SubMenu({ label, items, expand, onExpand, id, layoutClassName }) {
  const { ref: elementRef, size } = useElementSize()
  return (
    <div className={cx(styles.componentSubMenu, expand && styles.isExpanded)}>
      <button
        data-x={expand ? 'close-submenu' : 'open-submenu'}
        onClick={() => onExpand(!expand)}
        className={cx(styles.button)}>
        <span>{label}</span>
        <span className={cx(styles.icon, expand && styles.isRotated)}><Icon icon={chevronDown} /></span>
      </button>
      <div
        style={{ height: expand ? (size.height + 'px') : '0px', opacity: expand ? '1' : '0' }}
        className={cx(styles.expand, expand && styles.isExpanded)}>
        <div ref={elementRef} className={styles.inner}>
          <ul className={styles.innerMenu}>
            {items && items.map((item) => {
              const { href, label } = extractLinkInfo(item)
              return (
                <li key={item._key}>
                  <SubMenuLink {... { href, label }} />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

function SubMenuLink({ layoutClassName = undefined, href, label }) {
  return (
    <a className={cx(styles.componentSubMenuLink, layoutClassName)} {...{ href }} data-x='link-in-menu-submenu'>
      <span className={styles.linkText}>{label}</span>
    </a>
  )
}
