import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { LanguageSwitch } from '/features/buildingBlocks/LanguageSwitch'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { MenuDesktop } from '/features/buildingBlocks/MenuDesktop'
import { routeMap } from '/routeMap'

import styles from './NavigationDesktop.css'

export function NavigationDesktop({ items, button, linksToTranslations, layoutClassName }) {
  const [subMenuItem, setSubMenuItem] = React.useState(null)

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <MenuDesktop onActiveItemChange={handleActiveItemChange} {... { items, subMenuItem }} />

      <div className={styles.extra}>
        <LanguageSwitch {...{ linksToTranslations }} layoutClassName={styles.languageLayout} />

        {button?.ref?.title && (
          <ButtonPrimary
            dataX='cta-in-menu'
            href={determineDocumentPathSync({ document: button.ref, routeMap })}
          >
            {button.ref.title}
          </ButtonPrimary>
        )}
      </div>
    </div>
  )

  function handleActiveItemChange(item) {
    setSubMenuItem(subMenuItem === item ? null : item)
  }
}
