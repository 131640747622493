import { LinkMenu } from '/features/buildingBlocks/Link'
import { extractLinkInfo } from '/machinery/extractLinkInfo'
import { useElementSize } from '@kaliber/use-element-size'
import { Icon } from '/features/buildingBlocks/Icon'

import chevronDown from '/images/icons/chevron-down.raw.svg'
import triangleIcon from '/images/icons/triangle.raw.svg'

import styles from './MenuDesktop.css'

export function MenuDesktop({ items, subMenuItem, onActiveItemChange }) {
  return (
    <ul className={styles.component}>
      {items && items.map((x, index) => {
        return (
          <li className={styles.menuItem} key={index}>
            {['internalLink', 'externalLink'].includes(x._type)
              ? <LinkMenu active={x.isActive} dataX='link-in-menu' href={extractLinkInfo(x)?.href} target={extractLinkInfo(x)?.target}>
                {extractLinkInfo(x)?.label}
              </LinkMenu>
              :
              <SubMenu
                layoutClassName={styles.subMenu}
                id={x._id}
                expand={x === subMenuItem}
                onExpand={expand => onActiveItemChange(expand ? x : null)}
                items={x.items}
                label={x.label}
                active={x.isActive}
              />
            }
          </li>
        )
      })}
    </ul>
  )
}

function SubMenu({ label, items, expand, onExpand, id, layoutClassName, active }) {
  const { ref: elementRef, size } = useElementSize()

  return (
    <div
      onMouseEnter={() => onExpand(!expand)}
      onMouseLeave={() => onExpand(expand)}
      className={cx(styles.componentSubMenu, active && styles.isActive, layoutClassName)}>
      <button
        className={styles.button}
        aria-controls={id}
        aria-expanded={expand}
      >
        <div>{label}</div>
        <Icon label='chevron-down' icon={chevronDown} />
      </button>
      <div
        className={styles.wrapper}
        style={{
          height: expand ? (size.height + 'px') : '0px',
          opacity: expand ? '1' : '0',
          pointerEvents: expand ? 'auto' : 'none'
        }}
      >
        <Icon layoutClassName={styles.triangle} icon={triangleIcon} />
        <div className={styles.inner} ref={elementRef}>
          <ul className={cx(styles.list, expand && styles.expand)}>
            {items && items.map((item) => {
              const { href, label } = extractLinkInfo(item)
              return (
                <li className={styles.listItem} key={item._key}>
                  <SubMenuLink active={item.isActive} {...{ label, href }} />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

function SubMenuLink({ active, layoutClassName = undefined, href, label }) {
  return (
    <a className={cx(styles.componentSubMenuLink, active && styles.isActive, layoutClassName)} {...{ href }} data-x='link-in-menu-submenu'>
      <span className={styles.linkText}>{label}</span>
    </a>
  )
}
