import { useMediaQuery } from '@kaliber/use-media-query'
import { ContainerLg } from '/features/buildingBlocks/Container'
import { Logo } from '/features/buildingBlocks/Logo'
import { NavigationDesktop } from '/features/buildingBlocks/NavigationDesktop'
import { NavigationMobile } from '/features/buildingBlocks/NavigationMobile'

import styles from './SiteHeader.css'
import media from '/cssGlobal/media.css'

export function SiteHeader({ menu, linksToTranslations, layoutClassName }) {
  const { items, contact: button } = menu
  const isLg = useMediaQuery(`(min-width: ${media.breakpointLg})`)
  const showDesktopMenu = isLg === null || isLg === true
  const showMobileMenu = isLg === null || isLg === false

  return (
    <header className={cx(styles.component, layoutClassName)}>
      <ContainerLg layoutClassName={styles.container}>
        <nav className={styles.layout} data-x='site-navigation'>
          <Logo layoutClassName={styles.logo} dataX='link-in-menu-to-home' />
          {showDesktopMenu && <NavigationDesktop layoutClassName={styles.navigationDesktop} {...{ button, items, linksToTranslations }} />}
          {showMobileMenu && <NavigationMobile layoutClassName={styles.navigationMobile} {...{ button, items, linksToTranslations }} />}
        </nav>
      </ContainerLg>
    </header>
  )
}
